<template>
  <div class="auth-wrapper forgot-password px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <h2 class="brand-text text-primary">
          아이디 찾기/비밀번호 변경
        </h2>

        <b-row>
          <!-- find email -->
          <b-col
            cols="6"
            class="column-form left border-right"
          >
            <b-card-title class="column-title">
              이메일 찾기
            </b-card-title>

            <!-- form -->
            <validation-observer ref="findEmail">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="findEmail"
              >
                <!-- email -->
                <div class="column-input">
                  <b-form-group
                    label="담당자명을 입력하세요"
                    label-for="forgot-password-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="담당자명"
                      rules="required"
                    >
                      <b-form-input
                        id="forgot-password-name"
                        v-model="userName"
                        :state="errors.length > 0 ? false:null"
                        name="forgot-password-name"
                        placeholder="홍길동"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- phone number -->
                  <b-form-group
                    label="연락처를 입력하세요."
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="연락처"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="phone"
                        v-model="phone"
                        :state="errors.length > 0 ? false:null"
                        name="register-email"
                        placeholder="0000000000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <!-- submit button -->
                <b-button
                  class="submit-btn"
                  variant="primary"
                  block
                  type="submit"
                >
                  이메일 찾기
                </b-button>
              </b-form>
            </validation-observer>

            <!-- modal fail -->
            <b-modal
              id="modal-danger"
              v-model="userNotFound"
              ok-only
              ok-variant="danger"
              ok-title="확인"
              modal-class="modal-danger"
              centered
              title="회원님의 이메일을 찾을 수 없습니다."
            >
              <b-card-text>
                입력하신 정보가 맞는지 다시 한 번 확인해주세요.
              </b-card-text>
            </b-modal>

            <!-- modal success -->
            <b-modal
              id="modal-primary"
              v-model="userFound"
              ok-only
              ok-variant="primary"
              ok-title="확인"
              modal-class="modal-primary"
              centered
              title="회원님의 이메일 찾기가 완료되었습니다."
            >
              <b-card-text class="text-wrap">
                입력해주신 정보로 가입된 이메일이 <strong class="text-info">{{ `총 ${members.length}개` }}</strong> 있습니다.<br>
                이메일을 선택하여 로그인 / 비민번호 변경을 진행해주세요.<br>
                개인정보 보호를 위해 @이하 주소의 일부는 *로 표기됩니다.
              </b-card-text>
              <p
                v-for="email in members"
                :key="email"
              >
                {{ email }}
              </p>
            </b-modal>

          </b-col>

          <!-- reset password -->
          <b-col
            cols="6"
            class="column-form right"
          >
            <b-card-title class="column-title">
              비밀번호 변경
            </b-card-title>

            <!-- form -->
            <validation-observer ref="resetPassword">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="resetPassword"
              >
                <div class="column-input">
                  <!-- email -->
                  <b-form-group
                    label="이메일 주소를 입력하세요."
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="이메일"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="register-email"
                        placeholder="projectx@totalsolution.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- submit button -->
                <b-button
                  class="submit-btn"
                  variant="primary"
                  block
                  type="submit"
                >
                  메일 발송하기
                </b-button>
              </b-form>
            </validation-observer>

            <!-- modal success -->
            <b-modal
              id="modal-primary"
              v-model="emailSent"
              ok-variant="primary"
              cancel-title="확인"
              ok-title="로그인"
              modal-class="modal-primary"
              centered
              title="회원님의 비밀번호 변경 링크가 메일로 발송되었습니다."
              @ok="handleLogin"
            >
              <b-card-text class="text-wrap">
                발송된 메일에 포함된 링크를 클릭하여 비밀번호 변경 후 로그인을 진행해주세요.
              </b-card-text>
            </b-modal>

          </b-col>
        </b-row>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" />
            로그인 바로가기
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BCardText,
  BCol, BRow,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BLink,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BCardText,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userName: '',
      userEmail: '',
      phone: '',
      members: [],
      userFound: false,
      userNotFound: false,
      emailSent: false,
      // validation
      required,
      email,
    }
  },
  methods: {
    findEmail() {
      this.$refs.findEmail.validate().then(success => {
        if (success) {
          useJwt.findUser({
            name: this.userName,
            phone: this.phone,
          }).then(response => {
            this.members = response.data.payload.members
            this.userFound = true
          }).catch(() => {
            this.userNotFound = true
          })
        }
      })
    },
    resetPassword() {
      this.$refs.resetPassword.validate().then(success => {
        if (success) {
          this.emailSent = true
        }
      })
    },
    handleLogin() {
      this.emailSent = false
      this.$router.replace({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/vue/pages/page-auth';

.column-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 300px;
  .column-title {
    margin-bottom: 1rem;
  }
  .column-input {
    min-height: 150px;
  }
  .submit-btn {
    margin-top: 1rem;
    align-self: end;
  }
}
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
